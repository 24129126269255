jQuery(document).ready(function($) {

  function detectScreenSize() {
    return window.innerWidth > 767 ? "desktop" : "mobile";
  }

  const screenSize = detectScreenSize();

  console.log(screenSize)
  function injectVideoSrc() {

    const mobileSources = [
      "./dist/img/videos/Montaigne_Way_Animated_Typography_Circle_Vertical.m4v",
      "./dist/img/videos/Montaigne_Way_Animated_Typography_Spiral_Vertical.m4v",
      "./dist/img/videos/Montaigne_Way_Animated_Typography_Staggered_Vertical.m4v"
    ];

    const desktopSources = [
      "./dist/img/videos/Montaigne_Way_Animated_Typography_Circle.m4v",
      "./dist/img/videos/Montaigne_Way_Animated_Typography_Spiral.m4v",
      "./dist/img/videos/Montaigne_Way_Animated_Typography_Staggered.m4v"
    ];

    if (screenSize === "mobile") {
      const randomIndex = Math.floor(Math.random() * mobileSources.length);
      console.log(randomIndex)
      $('#mobile-source').attr('src', mobileSources[randomIndex]);
      $('#mobile-video')[0].load()
    }

    if (screenSize === "desktop") {
      const randomIndex = Math.floor(Math.random() * desktopSources.length);
      $('#desktop-source').attr('src', desktopSources[randomIndex]);
      $('#desktop-video')[0].load()
    }
  }

  injectVideoSrc()
});
